@import "../../../styles/fonts";
@import "../../../styles/animations";

.vehicle-wraps {
    box-sizing: border-box;
    position: relative;
    display: block;

    .study-wrapper {
        .section5 {
            .results {
                padding: 0px;
                width: 100%;

                .paragraphs {
                    columns: 2;
                }
            }

            @media only screen and (max-width: 800px) {
                .results {
                    .paragraphs {
                        columns: 1;
                    }
                }
            }
        }
    }

    .projects-block {
        box-sizing: border-box;
        position: relative;
        display: block;
        padding: 75px 0px;
    }
}
