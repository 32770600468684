@import "../../styles/fonts";
@import "../../styles/animations";

.main-heading {
    box-sizing: border-box;
    position: relative;
    display: block;

    .back-btn {
        box-sizing: border-box;
        position: relative;
        display: inline-block;
        vertical-align: top;
        text-decoration: none;

        .arrow {
            box-sizing: border-box;
            position: relative;
            display: inline-block;
            vertical-align: middle;
            width: 21px;
            height: 15px;
            background-image: url("data:image/svg+xml,%3Csvg width='23' height='17' viewBox='0 0 23 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M23 7.03629L5.5272 7.03629L12.1378 -9.41475e-07L7.98418 -2.03083e-06L3.22506e-07 8.49781L7.98418 17L12.1337 17L5.52719 9.9637L23 9.96371L23 7.03629Z' fill='url(%23paint0_linear_195_99)'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_195_99' x1='1.65911' y1='15.979' x2='15.2238' y2='3.62745' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23959595'/%3E%3Cstop offset='1' stop-color='%23959595'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");
            background-repeat: no-repeat;
            background-size: 100%;
            background-position: center;
        }

        .label {
            box-sizing: border-box;
            position: relative;
            display: inline-block;
            vertical-align: middle;
            padding: 0px 0px 0px 10px;
            font-size: 19px;
            line-height: 28px;
            color: #959595;
        }
    }

    .back-btn:hover {
        .arrow {
            background-image: url("data:image/svg+xml,%3Csvg width='23' height='17' viewBox='0 0 23 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M23 7.03629L5.5272 7.03629L12.1378 -9.41475e-07L7.98418 -2.03083e-06L3.22506e-07 8.49781L7.98418 17L12.1337 17L5.52719 9.9637L23 9.96371L23 7.03629Z' fill='url(%23paint0_linear_195_99)'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_195_99' x1='1.65911' y1='15.979' x2='15.2238' y2='3.62745' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23FC6C5A'/%3E%3Cstop offset='1' stop-color='%23FC6C5A'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");
        }

        .label {
            color: #FC6C5A;
        }
    }

    .title-holder {
        box-sizing: border-box;
        position: relative;
        display: block;

        .title {
            box-sizing: border-box;
            position: relative;
            display: block;
            margin: 0px;
            font-family: $poppins-semibold;
            font-weight: normal;
            font-size: 60px;
            line-height: 75px;
            letter-spacing: -0.05rem;
            text-decoration: underline;
            text-decoration-color: #FC6C5A;
            text-underline-offset: 6px;
            text-decoration-thickness: 8px;
        }

        .extra {
            box-sizing: border-box;
            position: absolute;
            display: block;
            right: 0px;
            top: 0px;
            width: 100%;
            max-width: 760px;
            font-family: $poppins-light;
            font-size: 42px;
            line-height: 60px;
            letter-spacing: -0.05rem;

            b {
                font-weight: normal;
                color: #FC6C5A;
            }
        }
    }

    .title-holder.has-back {
        margin: 10px 0px 0px 0px;
    }

    .title-holder.has-extra {
        padding: 0px 800px 0px 0px;
        min-height: 126px;
    }

    @media only screen and (max-width: 1460px) {
        .title-holder {
            min-height: initial;

            .title {
                font-size: 30px;
                line-height: 38px;
                text-decoration-thickness: 4px;
            }

            .extra {
                position: relative;
                margin: 20px 0px 0px;
                right: auto;
                max-width: none;
                font-size: 30px;
                line-height: 38px;
            }
        }

        .title-holder.has-extra {
            padding: 0px;
        }
    }
}
