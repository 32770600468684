@import "../../styles/fonts";
@import "../../styles/animations";

.section-heading {
    box-sizing: border-box;
    position: relative;
    display: block;
    margin: 0px;
    border-top: 1px solid #000000;
    padding: 10px 0px;
    font-family: $poppins-semibold;
    font-weight: normal;
    font-size: 40px;
    line-height: 70px;
    letter-spacing: -0.05rem;
    text-align: left;
}

@media only screen and (max-width: 1100px) {
    .section-heading {
        font-size: 30px;
        line-height: 38px;
    }
}
