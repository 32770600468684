@import "../../styles/fonts";
@import "../../styles/animations";

.latest-posts {
    box-sizing: border-box;
    position: relative;
    display: block;

    .loader-holder {
        box-sizing: border-box;
        position: relative;
        display: block;
        padding: 80px 0px;
    }

    .posts {
        box-sizing: border-box;
        position: relative;
        display: block;
        margin-right: -10px;
        padding: 80px 0px 50px 0px;

        .post {
            box-sizing: border-box;
            position: relative;
            display: inline-block;
            vertical-align: top;
            padding: 0px 10px 30px 0px;
            width: 25%;
        }

        @media only screen and (max-width: 800px) {
            .post {
                width: 50%;
            }
        }
    }

    .not-found {
        box-sizing: border-box;
        position: relative;
        display: block;
        margin: 0px;
        padding: 80px 0px;
        font-size: 19px;
        line-height: 30px;
    }
}