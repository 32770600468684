@import "../../styles/fonts";
@import "../../styles/animations";

.not-found {
    box-sizing: border-box;
    position: relative;
    display: block;

    .intro-block {
        box-sizing: border-box;
        position: relative;
        display: block;
        padding: 75px 0px;
        background: #000000;

        .main-heading {
            .title-holder {
                .title {
                    color: #FFFFFF;
                }
            }
        }

        .paragraphs {
            box-sizing: border-box;
            position: relative;
            display: block;
            margin: 75px 0px 0px 0px;

            p {
                box-sizing: border-box;
                position: relative;
                display: block;
                margin: 44px 0px 0px 0px;
                font-size: 19px;
                line-height: 30px;
                color: #ffffff;

                a {
                    font-family: $poppins-semibold;
                    text-decoration: none;
                    color: #FC6C5A;
                }
            }

            p:first-child {
                margin: 0px;
            }
        }
    }
}
