@import "../../styles/fonts";
@import "../../styles/animations";

.terms {
    box-sizing: border-box;
    position: relative;
    display: block;

    .terms-wrapper {
        box-sizing: border-box;
        position: relative;
        display: block;
        padding: 75px 0px 0px 0px;
        background: #f0f0f0;

        .blocks {
            box-sizing: border-box;
            position: relative;
            display: block;
            margin: 75px 0px 0px 0px;

            .block {
                box-sizing: border-box;
                position: relative;
                display: block;
                padding: 0px 0px 75px 0px;

                .paragraphs {
                    box-sizing: border-box;
                    position: relative;
                    display: block;
                    margin: 20px 0px 0px 0px;

                    p {
                        box-sizing: border-box;
                        position: relative;
                        display: block;
                        margin: 20px 0px 0px 0px;
                        font-size: 19px;
                        line-height: 30px;

                        a {
                            font-family: $poppins-semibold;
                            font-weight: normal;
                            text-decoration: none;
                            color: #FC6C5A;
                        }
                    }

                    p:first-child {
                        margin: 0px;
                    }
                }
            }
        }
    }
}
