@import "../../../styles/fonts";
@import "../../../styles/animations";

.synthesia {
    box-sizing: border-box;
    position: relative;
    display: block;

    .projects-block {
        box-sizing: border-box;
        position: relative;
        display: block;
        padding: 75px 0px;
    }
}
