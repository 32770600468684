@import "../../styles/fonts";
@import "../../styles/animations";

.contact {
    box-sizing: border-box;
    position: relative;
    display: block;

    .form-block {
        box-sizing: border-box;
        position: relative;
        display: block;
        padding: 75px 0px;
        background: #000000;

        .main-heading {
            .title-holder {
                .title {
                    color: #FFFFFF;
                }

                .extra {
                    max-width: 850px;
                    color: #FFFFFF;
                }

                @media only screen and (max-width: 1460px) {
                    .extra {
                        max-width: none;
                    }
                }
            }
        }

        .form-screen {
            box-sizing: border-box;
            position: relative;
            display: block;
            margin: 75px 0px 0px 0px;

            .form-row {
                box-sizing: border-box;
                position: relative;
                display: block;
                width: 100%;

                .form-col {
                    box-sizing: border-box;
                    position: relative;
                    display: inline-block;
                    vertical-align: top;
                }

                .form-col1 {
                    padding: 0px 2px 0px 0px;
                    width: 44%;
                }

                .form-col2 {
                    padding: 0px 0px 0px 2px;
                    width: 56%;
                }

                .text-input {
                    box-sizing: border-box;
                    position: relative;
                    display: block;
                    margin: 4px 0px 0px 0px;
                    padding: 12px 15px;
                    border: none;
                    outline: none;
                    width: 100%;
                    background: #ffffff;
                    font-size: 19px;
                    line-height: 30px;
                }

                .text-input:first-child {
                    margin: 0px;
                }

                .text-input.disabled {
                    color: #757575;
                }

                .text-area {
                    box-sizing: border-box;
                    position: relative;
                    display: block;
                    padding: 12px 15px;
                    border: none;
                    outline: none;
                    width: 100%;
                    height: 286px;
                    background: #ffffff;
                    font-family: $poppins-regular; /* The textarea HTML tag has a default font-family */
                    font-size: 19px;
                    line-height: 30px;
                    resize: none;
                }

                .text-area.disabled {
                    color: #757575;
                }

                @media only screen and (max-width: 800px) {
                    .form-col1 {
                        padding: 0px;
                        width: 100%;
                    }

                    .form-col2 {
                        margin: 4px 0px 0px 0px;
                        padding: 0px;
                        width: 100%;
                    }
                }
            }

            .terms-row {
                padding: 50px 0px 0px 0px;
                text-align: center;

                .checkbox-wrapper {
                    box-sizing: border-box;
                    position: relative;
                    display: inline-block;
                    vertical-align: top;
                    padding: 0px 0px 0px 40px;
                    text-align: left;

                    .checkbox-input {
                        box-sizing: border-box;
                        position: absolute;
                        display: block;
                        margin: 0px;
                        left: 0px;
                        top: 0px;
                        width: 30px;
                        height: 30px;
                        accent-color: #FC6C5A;
                    }

                    .checkbox-label {
                        box-sizing: border-box;
                        position: relative;
                        display: inline-block;
                        vertical-align: top;
                        font-size: 19px;
                        line-height: 30px;
                        color: #ffffff;

                        a {
                            font-family: $poppins-semibold;
                            font-weight: normal;
                            text-decoration: none;
                            color: #FC6C5A;
                        }
                    }
                }
            }

            .buttons-holder {
                box-sizing: border-box;
                position: relative;
                display: block;
                margin: 50px 0px 0px 0px;

                .submit-btn {
                    box-sizing: border-box;
                    position: relative;
                    display: block;
                    margin: 0px auto;
                    padding: 18px 10px;
                    border: 3px solid #FC6C5A;
                    border-radius: 45px;
                    width: 100%;
                    max-width: 164px;
                    background-color: #FC6C5A;
                    font-family: $poppins-semibold;
                    font-size: 19px;
                    color: #ffffff;
                    cursor: pointer;
                    transition: background-color 0.3s;
                }

                .submit-btn:hover {
                    background-color: #000000;
                }
            }

            .response {
                box-sizing: border-box;
                position: relative;
                display: block;
                margin: 100px 0px 0px 0px;
                text-align: center;

                p {
                    box-sizing: border-box;
                    position: relative;
                    display: block;
                    margin: 44px 0px 0px 0px;
                    font-size: 19px;
                    line-height: 30px;
                    color: #ffffff;
                }

                p:first-child {
                    margin: 0px;
                }
            }
        }
    }
}
