@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
} 

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

@keyframes scrollLeft {
    0% { transform: translateX(0); }
    100% { transform: translateX(-100%); }
}

@keyframes scrollRight {
    0% { transform: translateX(0); }
    100% { transform: translateX(100%); }
}