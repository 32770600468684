@import "../../styles/fonts";
@import "../../styles/animations";


.video-screen {
    box-sizing: border-box;
    position: relative;
    display: block;
    width: 100%;
    height: 100%;

    .main-video {
        box-sizing: border-box;
        position: relative;
        display: block;
        width: 100%;
        height: 100%;
        background: #000000;
        object-fit: cover;
    }

    .short-video {
        box-sizing: border-box;
        position: absolute;
        display: block;
        left: 0px;
        top: 0px;
        width: 100%;
        height: 100%;
        background: #000000;
        object-fit: cover;
    }

    .overlay {
        box-sizing: border-box;
        position: absolute;
        display: block;
        left: 0px;
        top: 0px;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.6);
        transition: background 0.3s;
        overflow: hidden;

        .inner-content {
            height: 100%;;

            .overlay-elements {
                box-sizing: border-box;
                position: relative;
                display: block;
                margin: 0px auto;
                padding: 0px 244px 0px 0px;
                top: 50%;
                transform: translateY(-50%);
                width: 100%;
                max-width: 1140px;

                .svg {
                    box-sizing: border-box;
                    position: relative;
                    display: block;
                    width: 100%;
                }

                .play-btn {
                    box-sizing: border-box;
                    position: absolute;
                    display: block;
                    padding: 20px;
                    right: 10px;
                    top: 50%;
                    transform: translateY(-50%);
                    height: 224px;
                    width: 224px;
                    cursor: pointer;

                    .play-bg {
                        box-sizing: border-box;
                        position: absolute;
                        display: block;
                        border-radius: 50%;
                        left: 0px;
                        top: 0px;
                        width: 100%;
                        height: 100%;
                        background: rgba(0, 0, 0, 0.8);
                        filter: blur(4px);
                        transform: scale(1);
                        transition: transform 0.3s;
                    }

                    .play-border {
                        box-sizing: border-box;
                        position: absolute;
                        display: block;
                        border: solid 2px #FC6C5A;
                        border-radius: 50%;
                        left: 0px;
                        top: 0px;
                        width: 100%;
                        height: 100%;
                        transform: scale(1);
                        transition: transform 0.3s;
                    }

                    .play-content {
                        box-sizing: border-box;
                        position: relative;
                        display: block;
                        top: 50%;
                        transform: translateY(-50%);

                        .arrows {
                            box-sizing: border-box;
                            position: relative;
                            display: block;
                            margin: 0px auto;
                            width: 81px;
                            white-space: nowrap;
                            overflow: hidden;

                            .arrow {
                                box-sizing: border-box;
                                position: relative;
                                display: inline-block;
                                vertical-align: top;
                                left: 0px;
                                width: 81px;
                                height: 16px;
                                background-image: url("data:image/svg+xml,%3Csvg width='61' height='16' viewBox='0 0 61 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M60.7071 8.70711C61.0976 8.31658 61.0976 7.68342 60.7071 7.29289L54.3432 0.928932C53.9526 0.538408 53.3195 0.538408 52.9289 0.928932C52.5384 1.31946 52.5384 1.95262 52.9289 2.34315L58.5858 8L52.9289 13.6569C52.5384 14.0474 52.5384 14.6805 52.9289 15.0711C53.3195 15.4616 53.9526 15.4616 54.3432 15.0711L60.7071 8.70711ZM0 9H60V7H0V9Z' fill='%23FC6C5A'/%3E%3C/svg%3E%0A");
                                background-repeat: no-repeat;
                                background-size: 61px 16px;
                                background-position: 10px 0px;
                                transition: left 0.3s;
                            }
                        }

                        .label {
                            box-sizing: border-box;
                            position: relative;
                            display: block;
                            margin: 20px 0px 0px 0px;
                            font-size: 22px;
                            line-height: 33px;
                            text-align: center;
                            color: #ffffff;
                        }
                    }
                }

                .play-btn:hover {
                    .play-bg {
                        transform: scale(1.2);
                    }

                    .play-border {
                        transform: scale(1.1);
                    }

                    .play-content {
                        .arrows {
                            .arrow {
                                left: -100%;
                            }
                        }
                    }
                }
            }

            .overlay-elements.no-svg {
                padding: 0px;

                .play-btn {
                    position: relative;
                    margin: 0px auto;
                    right: auto;
                    top: 0px;
                    transform: none;
                }
            }

            @media only screen and (max-width: 800px) {
                .overlay-elements {
                    padding: 0px 170px 0px 0px;

                    .play-btn {
                        height: 150px;
                        width: 150px;

                        .play-content {
                            .label {
                                font-size: 14px;
                                line-height: 20px;
                            }
                        }
                    }
                }
            }

            @media only screen and (max-width: 540px) {
                .overlay-elements {
                    padding: 0px;

                    .svg {
                        display: none;
                    }

                    .play-btn {
                        position: relative;
                        margin: 0px auto;
                        right: auto;
                        top: 0px;
                        transform: none;
                    }
                }
            }
        }
    }

    .close-btn {
        box-sizing: border-box;
        position: absolute;
        display: block;
        top: 0px;
        right: 0px;
        width: 80px;
        height: 80px;
        background-color: #FC6C5A;
        background-image: url("data:image/svg+xml,%3Csvg width='34' height='34' viewBox='0 0 34 34' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='30.4355' y='0.736328' width='4' height='42' transform='rotate(45 30.4355 0.736328)' fill='white'/%3E%3Crect x='33.2637' y='30.4346' width='4' height='42' transform='rotate(135 33.2637 30.4346)' fill='white'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        background-size: 34px;
        background-position: center;
        cursor: pointer;
    }

    .close-btn:hover {
        background-color: #FF841A;
    }

    @media only screen and (max-width: 540px) {
        .close-btn {
            width: 40px;
            height: 40px;
            background-size: 17px;
        }
    }
}

.video-screen.fullscreen {
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 3;
}