@import "../../styles/fonts";
@import "../../styles/animations";


.video-static {
    box-sizing: border-box;
    position: relative;
    display: block;
    width: 100%;
    height: 100%;

    video {
        box-sizing: border-box;
        position: relative;
        display: block;
        width: 100%;
        height: 100%;
        background: #000000;
        object-fit: cover;
    }
}
