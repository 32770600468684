@import "../../styles/fonts";
@import "../../styles/animations";

.home {
    box-sizing: border-box;
    position: relative;
    display: block;

    .intro-block {
        box-sizing: border-box;
        position: relative;
        display: block;
        overflow: hidden;

        .screen1 {
            box-sizing: border-box;
            position: relative;
            display: block;
            height: calc(100vh - 90px);

            .arrows-holder {
                box-sizing: border-box;
                position: absolute;
                display: block;
                bottom: -61px;
                z-index: 1;

                .arrow {
                    box-sizing: border-box;
                    position: relative;
                    display: block;
                    margin: 20px 0px 0px 0px;
                    width: 37px;
                    height: 51px;
                    background-image: url("data:image/svg+xml,%3Csvg width='49' height='67' viewBox='0 0 49 67' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M20.2811 -6.27672e-06L20.2811 50.899L1.21579e-05 31.6422L9.51342e-06 43.7417L24.4937 67L49 43.7417L49 31.6541L28.7189 50.899L28.7189 -4.43257e-06L20.2811 -6.27672e-06Z' fill='%23FC6C5A'/%3E%3C/svg%3E%0A");
                    background-repeat: no-repeat;
                    background-size: 100%;
                    background-position: center;
                }

                .arrow:first-child {
                    margin: 0px;
                }

                .arrow:last-child {
                    background-image: url("data:image/svg+xml,%3Csvg width='37' height='51' viewBox='0 0 37 51' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15.3143 -4.73956e-06L15.3143 38.744L6.17996e-06 24.0858L4.16703e-06 33.2959L18.4952 51L37 33.296L37 24.0949L21.6857 38.744L21.6857 -3.34704e-06L15.3143 -4.73956e-06Z' fill='white'/%3E%3C/svg%3E%0A");
                }
            }
        }

        @media only screen and (max-width: 1200px) {
            .screen1 {
                height: auto;

                .arrows-holder {
                    display: none;
                }
            }
        }

        .screen2 {
            box-sizing: border-box;
            position: relative;
            display: block;
            padding: 75px 0px;
            background-color: #FC6C5A; /* For browsers that do not support gradients */
            background-image: linear-gradient(to top right, #FF841A, #FC6C5A);
            overflow: hidden;

            .bg-img1 {
                box-sizing: border-box;
                position: absolute;
                display: block;
                right: -945px;
                top: -1000px;
                width: 1532px;
                height: 1532px;
                background-image: url("data:image/svg+xml,%3Csvg width='1532' height='1532' viewBox='0 0 1532 1532' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg style='mix-blend-mode:screen'%3E%3Cpath d='M1432 766C1432 1133.82 1133.82 1432 766 1432C398.178 1432 100 1133.82 100 766C100 398.178 398.178 100 766 100C1133.82 100 1432 398.178 1432 766Z' stroke='url(%23paint0_linear_249_600)' stroke-width='200'/%3E%3C/g%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_249_600' x1='-167' y1='1103.5' x2='59.3777' y2='1553.55' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23FEA46A' stop-opacity='1'/%3E%3Cstop offset='1' stop-color='%23FD8572' stop-opacity='1'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");
                background-repeat: no-repeat;
                background-size: 100%;
            }

            .bg-img2 {
                box-sizing: border-box;
                position: absolute;
                display: block;
                left: -2620px;
                bottom: -2280px;
                width: 3682px;
                height: 3682px;
                background-image: url("data:image/svg+xml,%3Csvg width='3682' height='3682' viewBox='0 0 3682 3682' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg style='mix-blend-mode:screen'%3E%3Cpath d='M3482 1841C3482 2747.3 2747.3 3482 1841 3482C934.701 3482 200 2747.3 200 1841C200 934.701 934.701 200 1841 200C2747.3 200 3482 934.701 3482 1841Z' stroke='url(%23paint0_linear_249_598)' stroke-opacity='1' stroke-width='400'/%3E%3C/g%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_249_598' x1='-255.962' y1='3218.14' x2='1264.64' y2='4057.53' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23FE9247'/%3E%3Cstop offset='1' stop-color='%23FD8C5D'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");
                background-repeat: no-repeat;
                background-size: 100%;
                transform: rotate(45deg);
            }

            .splash-screen {
                .heading-holder {
                    width: 58%;

                    h2 {
                        font-size: 60px;
                        line-height: 75px;
                    }

                    @media only screen and (max-width: 1400px) {
                        h2 {
                            font-size: 40px;
                            line-height: 50px;
                        }
                    }

                    @media only screen and (max-width: 1000px) {
                        h2 {
                            font-size: 30px;
                            line-height: 38px;
                        }
                    }
                }

                .img1-holder {
                    width: 42%;

                    img {
                        max-width: 583px;
                    }
                }

                .img2-holder {
                    width: 59%;

                    img {
                        max-width: 586px;
                    }
                }

                .body-holder {
                    width: 41%;
                }
            }

            @media only screen and (max-width: 1000px) {
                .bg-img1 {
                    display: none;
                }

                .bg-img2 {
                    display: none;
                }

                .splash-screen {
                    .heading-holder {
                        width: 100%;
                    }

                    .img1-holder {
                        width: 100%;
                    }

                    .img2-holder {
                        width: 100%;
                    }

                    .body-holder {
                        width: 100%;
                    }
                }
            }
        }
    }

    .projects-block {
        box-sizing: border-box;
        position: relative;
        display: block;
        padding: 75px 0px 75px 0px;

        .projects-gallery {
            margin: 20px 0px 0px 0px;
        }
    }

    .clients-block {
        box-sizing: border-box;
        position: relative;
        display: block;
        padding: 75px 0px;
        background: #000000;
        overflow: hidden;

        .section-heading {
            border-top: 1px solid #ffffff;
            color: #ffffff;
        }

        .logos {
            box-sizing: border-box;
            position: relative;
            display: block;
            margin: 30px 0px 0px 0px;

            .logo {
                box-sizing: border-box;
                position: relative;
                display: block;
                padding: 0px 80px;

                img {
                    box-sizing: border-box;
                    position: relative;
                    display: block;
                }
            }
        }
    }
}
