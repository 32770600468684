@import "../../styles/fonts";
@import "../../styles/animations";


.projects-slider {
    box-sizing: border-box;
    position: relative;
    display: block;
    margin: 20px 0px 0px 0px;
}
