@import "../../styles/fonts";
@import "../../styles/animations";

.carousel {
    box-sizing: border-box;
    position: relative;
    display: block;
    text-align: center;
    overflow: hidden;

    .track {
        box-sizing: border-box;
        position: relative;
        display: inline-block;
        vertical-align: middle;
        text-align: left;

        .marquee {
            box-sizing: border-box;
            position: relative;
            display: inline-block;
            vertical-align: middle;
            margin: 0px auto;
            left: 0px;
            top: 0px;
            white-space: nowrap;
            overflow: hidden;

            .entries {
                box-sizing: border-box;
                position: relative;
                display: inline-block;
                vertical-align: middle;
                animation-name: none;
                animation-duration: 10s;
                animation-timing-function: linear;
                animation-iteration-count: infinite;

                .entry {
                    box-sizing: border-box;
                    position: relative;
                    display: inline-block;
                    vertical-align: middle;
                }
            }
        }

        .marquee1 {
            position: absolute;
        }

        .marquee1 {
            .entries.scroll-left {
                left: 0%;
            }
        }

        .marquee2 {
            .entries.scroll-left {
                left: 100%;
            }
        }

        .marquee1 {
            .entries.scroll-right {
                left: -100%;
            }
        }

        .marquee2 {
            .entries.scroll-right {
                left: 0%;
            }
        }
    }
}

.carousel.start {
    .track {
        .marquee {
            .entries.scroll-left {
                animation-name: scrollLeft;
            }

            .entries.scroll-right {
                animation-name: scrollRight;
            }
        }
    }
}