@import "../../styles/fonts";
@import "../../styles/animations";

.splash-screen {
    box-sizing: border-box;
    position: relative;
    display: block;

    .heading-holder {
        box-sizing: border-box;
        position: relative;
        display: inline-block;
        vertical-align: bottom;
        width: 62%;

        .svg {
            box-sizing: border-box;
            position: relative;
            display: block;
            margin: 0px 0px 40px 0px;
            width: 100%;
            max-width: 764px;
        }

        h2 {
            box-sizing: border-box;
            position: relative;
            display: block;
            margin: 0px;
            font-family: $poppins-semibold;
            font-weight: normal;
            font-size: 45px;
            line-height: 55px;
            letter-spacing: -0.1rem;
            color: #FFFFFF;
        }
    }

    .img1-holder {
        box-sizing: border-box;
        position: relative;
        display: inline-block;
        vertical-align: bottom;
        padding: 0px 0px 0px 40px;
        width: 38%;

        img {
            box-sizing: border-box;
            position: relative;
            display: block;
            margin: 0px auto;
            width: 100%;
            max-width: 490px;
        }
    }

    .img2-holder {
        box-sizing: border-box;
        position: relative;
        display: inline-block;
        vertical-align: top;
        padding: 60px 40px 0px 0px;
        width: 60%;

        img {
            box-sizing: border-box;
            position: relative;
            display: block;
            margin: 0px auto;
            width: 100%;
            max-width: 756px;
        }
    }

    .body-holder {
        box-sizing: border-box;
        position: relative;
        display: inline-block;
        vertical-align: top;
        width: 40%;

        .body-wrapper {
            box-sizing: border-box;
            position: relative;
            display: inline-block;
            vertical-align: top;
            padding: 75px 0px;

            .line {
                box-sizing: border-box;
                position: relative;
                display: block;
                width: 72px;
                height: 2px;
                background: #ffffff;
            }

            .paragraphs {
                box-sizing: border-box;
                position: relative;
                display: block;
                margin: 44px 0px 0px 0px;

                p {
                    box-sizing: border-box;
                    position: relative;
                    display: block;
                    margin: 44px 0px 0px 0px;
                    font-family: $poppins-light;
                    font-size: 29px;
                    line-height: 39px;
                    color: #ffffff;

                    b {
                        font-family: $poppins-semibold;
                        font-weight: normal;
                    }
                }

                p:first-child {
                    margin: 0px;
                }
            }
        }
    }

    .arrow {
        box-sizing: border-box;
        position: relative;
        display: block;
        margin: 0px auto;
        width: 37px;
        height: 51px;
        background-image: url("data:image/svg+xml,%3Csvg width='37' height='51' viewBox='0 0 37 51' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15.3143 -4.73956e-06L15.3143 38.744L6.17996e-06 24.0858L4.16703e-06 33.2959L18.4952 51L37 33.296L37 24.0949L21.6857 38.744L21.6857 -3.34704e-06L15.3143 -4.73956e-06Z' fill='white'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        background-size: 100%;
        background-position: center;
    }

    @media only screen and (max-width: 1460px) {
        .heading-holder {
            h2 {
                font-size: 30px;
                line-height: 38px;
            }
        }
    }

    @media only screen and (max-width: 1000px) {
        .heading-holder {
            width: 100%;

            .svg {
                margin: 0px auto 40px auto;
            }
        }

        .img1-holder {
            padding: 40px 0px 0px 0px;
            width: 100%;
        }

        .img2-holder {
            display: none;
        }

        .body-holder {
            width: 100%;

            .body-wrapper {
                .paragraphs {
                    p {
                        margin: 20px 0px 0px 0px;
                        font-size: 19px;
                        line-height: 30px;
                    }

                    p:first-child {
                        margin: 0px;
                    }
                }
            }
        }
    }
}