@import "../../styles/fonts";
@import "../../styles/animations";


.study-wrapper {
    box-sizing: border-box;
    position: relative;
    display: block;
    padding: 190px 0px 0px 0px;
    background-color: #000000;
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center top;
}

@media only screen and (max-width: 1020px) {
    .study-wrapper {
        background-size: auto;
    }
}