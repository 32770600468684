$poppins-bold: 'Poppins-Bold', sans-serif;
$poppins-bold-italic: 'Poppins-BoldItalic', sans-serif;
$poppins-italic: 'Poppins-Italic', sans-serif;
$poppins-light: 'Poppins-Light', sans-serif;
$poppins-light-italic: 'Poppins-LightItalic', sans-serif;
$poppins-medium: 'Poppins-Medium', sans-serif;
$poppins-medium-italic: 'Poppins-MediumItalic', sans-serif;
$poppins-regular: 'Poppins-Regular', sans-serif;
$poppins-semibold: 'Poppins-Semibold', sans-serif;
$poppins-semibold-italic: 'Poppins-SemiboldItalic', sans-serif;

@font-face {
    font-family: 'Poppins-Bold';
    src: url('./fonts/Poppins-Bold.eot');
    src: url('./fonts/Poppins-Bold.eot?#iefix') format('embedded-opentype'),
        url('./fonts/Poppins-Bold.woff2') format('woff2'),
        url('./fonts/Poppins-Bold.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins-BoldItalic';
    src: url('./fonts/Poppins-BoldItalic.eot');
    src: url('./fonts/Poppins-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('./fonts/Poppins-BoldItalic.woff2') format('woff2'),
        url('./fonts/Poppins-BoldItalic.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins-Italic';
    src: url('./fonts/Poppins-Italic.eot');
    src: url('./fonts/Poppins-Italic.eot?#iefix') format('embedded-opentype'),
        url('./fonts/Poppins-Italic.woff2') format('woff2'),
        url('./fonts/Poppins-Italic.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins-Light';
    src: url('./fonts/Poppins-Light.eot');
    src: url('./fonts/Poppins-Light.eot?#iefix') format('embedded-opentype'),
        url('./fonts/Poppins-Light.woff2') format('woff2'),
        url('./fonts/Poppins-Light.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins-LightItalic';
    src: url('./fonts/Poppins-LightItalic.eot');
    src: url('./fonts/Poppins-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('./fonts/Poppins-LightItalic.woff2') format('woff2'),
        url('./fonts/Poppins-LightItalic.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins-Medium';
    src: url('./fonts/Poppins-Medium.eot');
    src: url('./fonts/Poppins-Medium.eot?#iefix') format('embedded-opentype'),
        url('./fonts/Poppins-Medium.woff2') format('woff2'),
        url('./fonts/Poppins-Medium.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins-MediumItalic';
    src: url('./fonts/Poppins-MediumItalic.eot');
    src: url('./fonts/Poppins-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('./fonts/Poppins-MediumItalic.woff2') format('woff2'),
        url('./fonts/Poppins-MediumItalic.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins-Regular';
    src: url('./fonts/Poppins-Regular.eot');
    src: url('./fonts/Poppins-Regular.eot?#iefix') format('embedded-opentype'),
        url('./fonts/Poppins-Regular.woff2') format('woff2'),
        url('./fonts/Poppins-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins-SemiBold';
    src: url('./fonts/Poppins-SemiBold.eot');
    src: url('./fonts/Poppins-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('./fonts/Poppins-SemiBold.woff2') format('woff2'),
        url('./fonts/Poppins-SemiBold.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins-SemiBoldItalic';
    src: url('./fonts/Poppins-SemiBoldItalic.eot');
    src: url('./fonts/Poppins-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
        url('./fonts/Poppins-SemiBoldItalic.woff2') format('woff2'),
        url('./fonts/Poppins-SemiBoldItalic.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}