@import "../../styles/fonts";
@import "../../styles/animations";


.projects-gallery {
    box-sizing: border-box;
    position: relative;
    display: block;

    .projects {
        box-sizing: border-box;
        position: relative;
        display: block;

        .project {
            box-sizing: border-box;
            position: relative;
            display: inline-block;
            vertical-align: top;
            width: 100%;
        }

        .project1 {
            padding: 0px 10px 0px 0px;
            width: 50%;

            .project-link {
                max-width: 705px;
            }
        }

        .project2 {
            padding: 60px 0px 0px 10px;
            width: 50%;
            text-align: right;

            .project-link {
                max-width: 705px;
            }
        }

        .project3 {
            padding: 100px 0px 0px 0px;

            .project-link {
                max-width: 1353px;
            }
        }

        .project4 {
            padding: 100px 0px 0px 0px;
            text-align: right;

            .project-link {
                max-width: 1353px;
            }
        }

        .project5 {
            padding: 100px 10px 0px 0px;
            width: 50%;

            .project-link {
                max-width: 705px;
            }
        }

        .project6 {
            padding: 200px 0px 0px 10px;
            width: 50%;
            text-align: right;

            .project-link {
                max-width: 705px;
            }
        }

        .project7 {
            padding: 100px 0px 0px 0px;

            .project-link {
                max-width: 1353px;
            }
        }

        .project8 {
            padding: 100px 10px 0px 0px;
            width: 50%;

            .project-link {
                max-width: 705px;
            }
        }

        .project9 {
            padding: 380px 0px 0px 10px;
            width: 50%;
            text-align: right;

            .project-link {
                max-width: 705px;
            }
        }

        .project10 {
            padding: 100px 0px 0px 0px;
            text-align: right;

            .project-link {
                max-width: 1353px;
            }
        }

        .project11 {
            padding: 100px 0px 0px 0px;

            .project-link {
                max-width: 1353px;
            }
        }

        .project12 {
            padding: 100px 10px 0px 0px;
            width: 50%;

            .project-link {
                max-width: 705px;
            }
        }

        .project13 {
            padding: 100px 0px 0px 0px;
            text-align: right;

            .project-link {
                max-width: 1353px;
            }
        }

        @media only screen and (max-width: 850px) {
            .project {
                .project-link {
                    .brand {
                        font-size: 16px;
                        line-height: 24px;
                    }

                    .title {
                        font-size: 14px;
                        line-height: 22px;
                    }
                }
            }

            .project1 {

            }

            .project2 {
                padding: 0px 0px 0px 10px;
            }

            .project3 {
                padding: 20px 0px 0px 0px;
            }

            .project4 {
                padding: 20px 0px 0px 0px;
            }

            .project5 {
                padding: 20px 10px 0px 0px;
            }

            .project6 {
                padding: 20px 0px 0px 10px;
            }

            .project7 {
                padding: 20px 0px 0px 0px;
            }

            .project8 {
                padding: 20px 10px 0px 0px;
            }

            .project9 {
                padding: 20px 0px 0px 10px;
            }

            .project10 {
                padding: 20px 0px 0px 0px;
            }

            .project11 {
                padding: 20px 0px 0px 0px;
            }

            .project12 {
                padding: 20px 10px 0px 0px;
            }

            .project13 {
                padding: 20px 0px 0px 0px;
            }
        }
    }
}
