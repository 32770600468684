@import "../../styles/fonts";
@import "../../styles/animations";

.footer {
    box-sizing: border-box;
    position: relative;
    display: block;
    padding: 80px 0px;
    background-color: #FC6C5A;

    .inner {
        box-sizing: border-box;
        position: relative;
        display: block;
        margin: 0px auto;
        padding: 0px 100px;
        width: 100%;
        max-width: 1740px;

        .details {
            box-sizing: border-box;
            position: relative;
            display: block;

            .contact {
                box-sizing: border-box;
                position: relative;
                display: inline-block;
                vertical-align: bottom;

                .copyright {
                    box-sizing: border-box;
                    position: relative;
                    display: block;
                    font-size: 19px;
                    line-height: 28px;
                    color: #ffffff;
                }

                .address, .phone, .email {
                    box-sizing: border-box;
                    position: relative;
                    display: block;
                    font-size: 19px;
                    line-height: 28px;
                    color: #ffffff;
                }

                .address {
                    margin: 32px 0px 0px 0px;
                }

                .phone {
                    margin: 32px 0px 0px 0px;
                }
            }

            .links {
                box-sizing: border-box;
                position: relative;
                display: inline-block;
                vertical-align: bottom;
                padding: 0px 0px 0px 80px;

                .link {
                    box-sizing: border-box;
                    position: relative;
                    display: block;
                    left: 0px;
                    text-decoration: none;
                    white-space: nowrap;
                    transition: left 0.3s;

                    .arrow {
                        box-sizing: border-box;
                        position: relative;
                        display: inline-block;
                        vertical-align: middle;
                        width: 17px;
                        height: 12px;
                        background-image: url("data:image/svg+xml,%3Csvg width='17' height='12' viewBox='0 0 17 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 7.03321L12.9147 7.03321L8.02862 12H11.0986L17 6.00154L11.0986 0L8.03164 0L12.9147 4.9668L0 4.9668L0 7.03321Z' fill='white'/%3E%3C/svg%3E%0A");
                        background-repeat: no-repeat;
                        background-size: 100%;
                        background-position: center;
                    }

                    .name {
                        box-sizing: border-box;
                        position: relative;
                        display: inline-block;
                        vertical-align: middle;
                        padding: 0px 0px 0px 10px;
                        font-size: 19px;
                        line-height: 28px;
                        color: #ffffff;
                    }
                }

                .link:last-child {
                    margin: 32px 0px 0px 0px;
                }

                .link:hover {
                    left: 5px;
                }
            }
        }

        @media only screen and (max-width: 646px) {
            .details {
                .contact {
                    width: 100%;
                }

                .links {
                    padding: 32px 0px 0px 0px;
                    width: 100%;
                }
            }
        }

        .logo {
            box-sizing: border-box;
            position: absolute;
            display: block;
            margin: 0px;
            right: 100px;
            bottom: 0px;
            width: 100%;
            max-width: 488px;
        }

        @media only screen and (max-width: 1380px) {
            .logo {
                position: relative;
                margin: 50px auto 0px auto;
                right: auto;
                bottom: auto;
            }
        }
    }

    @media only screen and (max-width: 1020px) {
        .inner {
            padding: 0px 20px;
        }
    }
}
