@import "../../../styles/fonts";
@import "../../../styles/animations";

.post {
    box-sizing: border-box;
    position: relative;
    display: block;

    .content-block {
        box-sizing: border-box;
        position: relative;
        display: block;
        background: #ffffff;

        .loader-holder {
            box-sizing: border-box;
            position: relative;
            display: block;
            height: calc(100vh - 90px);

            .loader {
                top: 50%;
                transform: translateY(-50%);
            }
        }

        .article {
            box-sizing: border-box;
            position: relative;
            display: block;

            .banner {
                box-sizing: border-box;
                position: relative;
                display: block;
                max-width: 100%;
            }

            .main-heading {
                padding: 36px 0px 0px 0px;

                .title {
                    text-decoration-color: #000000;
                }
            }

            .date {
                box-sizing: border-box;
                position: relative;
                display: block;
                margin: 40px 0px 0px 0px;
                font-size: 16px;
                line-height: 30px;
                color: #FC6C5A;
            }

            .content-wrapper {
                box-sizing: border-box;
                position: relative;
                display: block;
                padding: 40px 0px;

                .article-content {
                    box-sizing: border-box;
                    position: relative;
                    display: inline-block;
                    vertical-align: top;
                    width: 100%;

                    span {
                        font-size: 19px !important;
                        line-height: 30px !important;
                        color: #000000 !important;
                    }

                    a {
                        color: #FC6C5A !important;
                    }

                    strong {
                        color: #FC6C5A !important;
                    }

                    p {
                        box-sizing: border-box !important;
                        position: relative !important;
                        display: block !important;
                        margin: 0px 0px 40px 0px !important;
                        font-size: 19px !important;
                        line-height: 30px !important;
                    }

                    ul {
                        box-sizing: border-box !important;
                        position: relative !important;
                        display: block !important;
                        margin: 0px 0px 40px 0px !important;

                        li {
                            font-size: 19px !important;
                            line-height: 30px !important;
                        }
                    }

                    img {
                        box-sizing: border-box !important;
                        position: relative !important;
                        display: block !important;
                        margin: 0px 0px 40px 0px !important;
                        width: 100% !important;
                        height: auto !important;
                        max-width: 752px !important;
                    }

                    video {
                        box-sizing: border-box !important;
                        position: relative !important;
                        display: block !important;
                        margin: 0px 0px 40px 0px !important;
                        width: 100% !important;
                        height: auto !important;
                        max-width: 752px !important;
                    }

                    figure {
                        box-sizing: border-box !important;
                        position: relative !important;
                        display: block !important;
                        margin: 0px 0px 40px 0px !important;
                        width: 100% !important;
                        height: auto !important;
                        max-width: 752px !important;
                    }
                }

                .article-content.no-imgs {
                    img {
                        display: none !important;
                    }
                }

                .article-content.has-custom {
                    padding: 0px 36px 0px 0px;
                    width: 55%;
                }

                .custom-imgs {
                    box-sizing: border-box;
                    position: relative;
                    display: block;
                    display: inline-block;
                    vertical-align: top;
                    width: 45%;

                    .img-wrapper {
                        box-sizing: border-box;
                        position: relative;
                        display: block;
                        margin: 20px 0px 0px 0px;

                        img {
                            box-sizing: border-box;
                            position: relative;
                            display: block;
                            width: 100%;
                            max-width: 700px;
                        }

                        .caption {
                            box-sizing: border-box;
                            position: relative;
                            display: block;
                            margin: 10px 0px 0px 0px;
                            font-size: 19px;
                            line-height: 30px;
                            text-align: right;
                        }
                    }

                    .img-wrapper:first-child {
                        margin: 0px;
                    }
                }

                @media only screen and (max-width: 700px) {
                    .article-content.has-custom {
                        padding: 0px;
                        width: 100%;
                    }

                    .custom-imgs {
                        width: 100%;

                        .img-wrapper {
                            .caption {
                                text-align: left;
                            }
                        }
                    }
                }
            }
        }
    }

    .latest-block {
        box-sizing: border-box;
        position: relative;
        display: block;
    }
}
