@import "./fonts";
@import "./animations";

body {
    box-sizing: border-box;
    position: relative;
    display: block;
    margin: 0px;
    padding: 0px;
    background: #F8F9FA;
    font-family: $poppins-regular;
    color: #000000;
}
