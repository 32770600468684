@import "../../styles/fonts";
@import "../../styles/animations";


.study-banner {
    box-sizing: border-box;
    position: relative;
    display: block;

    .logo {
        box-sizing: border-box;
        position: relative;
        display: block;
    }

    .breadcrumb {
        box-sizing: border-box;
        position: relative;
        display: block;
        margin: 30px 0px 0px 0px;

        .entry {
            box-sizing: border-box;
            position: relative;
            display: inline-block;
            vertical-align: bottom;
            padding: 0px 0px 0px 20px;

            .separator {
                box-sizing: border-box;
                position: absolute;
                display: block;
                left: 6px;
                top: 1px;
                font-size: 18px;
                color: #ffffff;
            }

            .link, .title {
                box-sizing: border-box;
                position: relative;
                display: block;
                font-size: 18px;
                text-decoration: none;
                color: #ffffff;
            }

            .link:hover {
                color: #FC6C5A;
            }
        }

        .entry:first-child {
            padding: 0px;
        }
    }

    @media only screen and (max-width: 1190px) {
        .breadcrumb {
            display: none;
        }
    }
}
