@import "../../styles/fonts";
@import "../../styles/animations";

.post-link {
    box-sizing: border-box;
    position: relative;
    display: block;
    text-decoration: none;
    color: #000000;

    img {
        box-sizing: border-box;
        position: relative;
        display: block;
        width: 100%;
    }

    .title {
        box-sizing: border-box;
        position: relative;
        display: block;
        margin: 10px 0px 0px 0px;
        font-family: $poppins-semibold;
        font-weight: normal;
        font-size: 21px;
        line-height: 31px;
    }

    .date {
        box-sizing: border-box;
        position: relative;
        display: block;
        margin: 3px 0px 0px 0px;
        font-size: 16px;
        line-height: 38px;
        color: #FC6C5A;
    }
}

.post-link:hover {
    .title {
        color: #FC6C5A;
    }
}

@media only screen and (max-width: 1100px) {
    .post-link {
        .title {
            font-size: 16px;
            line-height: 24px;
        }

        .date {
            font-size: 12px;
            line-height: 20px;
        }
    }
}
