@import "../../styles/fonts";
@import "../../styles/animations";


.study-body {
    box-sizing: border-box;
    position: relative;
    display: block;
    padding: 40px 0px 75px 0px;

    .brand {
        box-sizing: border-box;
        position: relative;
        display: block;
        width: 100%;
        font-family: $poppins-light;
        font-size: 40px;
        color: #FC6C5A;
    }

    .title {
        box-sizing: border-box;
        position: relative;
        display: block;
        margin: 10px 0px 0px 0px;
        width: 100%;
        max-width: 1160px;
        font-family: $poppins-semibold;
        font-weight: normal;
        font-size: 60px;
        line-height: 75px;
        color: #FFFFFF;
    }

    .summary {
        box-sizing: border-box;
        position: relative;
        display: block;
        margin: 20px 0px 0px 0px;
        width: 100%;
        max-width: 1160px;
        font-family: $poppins-light;
        font-size: 30px;
        color: #FFFFFF;
    }

    @media only screen and (max-width: 1400px) {
        .brand {
            font-size: 30px;
        }

        .title {
            font-size: 40px;
            line-height: 50px;
        }

        .summary {
            font-size: 30px;
        }
    }

    @media only screen and (max-width: 1400px) {
        .brand {
            font-size: 20px;
        }

        .title {
            font-size: 30px;
            line-height: 38px;
        }

        .summary {
            font-size: 20px;
        }
    }

    .text-section {
        box-sizing: border-box;
        position: relative;
        display: block;

        .text-body {
            box-sizing: border-box;
            position: relative;
            display: inline-block;
            vertical-align: top;

            .heading {
                box-sizing: border-box;
                position: relative;
                display: block;
                margin: 0px;
                font-family: $poppins-semibold;
                font-weight: normal;
                font-size: 26px;
                color: #FC6C5A;
            }

            .paragraphs {
                box-sizing: border-box;
                position: relative;
                display: block;
                margin: 20px 0px 0px 0px;
                columns: 1;
                column-gap: 36px;

                p {
                    box-sizing: border-box;
                    position: relative;
                    display: block;
                    margin: 20px 0px 0px 0px;
                    font-size: 19px;
                    line-height: 30px;
                    color: #FFFFFF;
                    break-inside: avoid;
                }

                p:first-child {
                    margin: 0px;
                }
            }
        }

        .items-body {
            box-sizing: border-box;
            position: relative;
            display: inline-block;
            vertical-align: top;

            .heading {
                box-sizing: border-box;
                position: relative;
                display: block;
                margin: 0px;
                font-family: $poppins-semibold;
                font-weight: normal;
                font-size: 26px;
                color: #FC6C5A;
            }

            .items {
                box-sizing: border-box;
                position: relative;
                display: block;
                margin: 20px 0px 0px 0px;
                padding: 0px;

                .item {
                    box-sizing: border-box;
                    position: relative;
                    display: block;
                    margin: 0px;
                    padding: 0px 0px 0px 40px;

                    .arrow {
                        box-sizing: border-box;
                        position: absolute;
                        display: block;
                        left: 0px;
                        top: 5px;
                        width: 22px;
                        height: 17px;
                        background-image: url("data:image/svg+xml,%3Csvg width='22' height='17' viewBox='0 0 22 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M-1.74211e-06 9.96371L16.7131 9.96371L10.39 17L14.363 17L22 8.50219L14.363 2.5113e-06L10.3939 1.81733e-06L16.7131 7.03629L-1.23026e-06 7.03629L-1.74211e-06 9.96371Z' fill='%23FC6C5A'/%3E%3C/svg%3E%0A");
                        background-repeat: no-repeat;
                        background-size: 100%;
                    }

                    .text {
                        box-sizing: border-box;
                        position: relative;
                        display: block;
                        font-size: 19px;
                        line-height: 30px;
                        color: #FFFFFF;
                    }
                }

                .item:first-child {
                    margin: 0px;
                }
            }
        }
    }

    .section1 {
        margin: 40px 0px 0px 0px;
        padding: 40px 0px 0px 0px;
        border-top: solid 1px #FFFFFF;

        .benefits {
            width: 24%;
        }

        .time {
            padding: 0px 0px 0px 20px;
            width: 16%;
        }

        .services {
            padding: 0px 0px 0px 20px;
            width: 27%;
        }

        .outcomes {
            padding: 0px 0px 0px 20px;
            width: 33%;
        }

        @media only screen and (max-width: 1450px) {
            .benefits {
                width: 70%;
            }

            .time {
                padding: 0px 0px 0px 20px;
                width: 30%;
            }

            .services {
                padding: 30px 0px 0px 0px;
                width: 30%;
            }

            .outcomes {
                padding: 30px 0px 0px 20px;
                width: 70%;
            }
        }

        @media only screen and (max-width: 800px) {
            .benefits {
                width: 100%;
            }

            .time {
                padding: 30px 0px 0px 0px;
                width: 100%;
            }

            .services {
                padding: 30px 0px 0px 0px;
                width: 100%;
            }

            .outcomes {
                padding: 30px 0px 0px 0px;
                width: 100%;
            }
        }
    }

    .section2 {
        box-sizing: border-box;
        position: relative;
        display: block;
        margin: 40px 0px 0px 0px;

        .img1 {
            box-sizing: border-box;
            position: relative;
            display: block;
            width: 100%;
        }
    }

    .section3 {
        margin: 40px 0px 0px 0px;

        .background {
            padding: 0px 18px 0px 0px;
            width: 50%;
        }

        .goals {
            padding: 0px 0px 0px 18px;
            width: 50%;
        }

        @media only screen and (max-width: 800px) {
            .background {
                padding: 0px;
                width: 100%;
            }

            .goals {
                padding: 30px 0px 0px 0px;
                width: 100%;
            }
        }
    }

    .section4 {
        box-sizing: border-box;
        position: relative;
        display: block;
        margin: 40px 0px 0px 0px;

        .col {
            box-sizing: border-box;
            position: relative;
            display: inline-block;
            vertical-align: top;
            width: 50%;

            img {
                box-sizing: border-box;
                position: relative;
                display: block;
                width: 100%;
            }
        }

        .col1 {
            padding: 0px 5px 0px 0px;
        }

        .col2 {
            padding: 0px 0px 0px 5px;
        }
    }

    .section5 {
        margin: 40px 0px 0px 0px;

        .results {
            padding: 0px 18px 0px 0px;
            width: 50%;
        }

        .engagement {
            padding: 0px 0px 0px 18px;
            width: 50%;
        }

        @media only screen and (max-width: 800px) {
            .results {
                padding: 0px;
                width: 100%;
            }

            .engagement {
                padding: 30px 0px 0px 0px;
                width: 100%;
            }
        }
    }
}

