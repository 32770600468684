@import "../../styles/fonts";
@import "../../styles/animations";


.animation-on-scroll {
    box-sizing: border-box;
    position: relative;
    display: block;
}

.animation-on-scroll.fade {
    top: 100px;
    opacity: 0;
    transition: top 1s ease-out, opacity 1s ease;
}

.animation-on-scroll.show.fade {
    top: 0px;
    opacity: 1;
}

.animation-on-scroll.scale {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 1s ease, transform 1s ease;
}

.animation-on-scroll.show.scale {
    opacity: 1;
    transform: scale(1);
}
