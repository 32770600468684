@import "../../styles/fonts";
@import "../../styles/animations";

.project-link {
    box-sizing: border-box;
    position: relative;
    display: inline-block;
    vertical-align: top;
    text-decoration: none;
    width: 100%;
    text-align: left;

    .img-holder {
        box-sizing: border-box;
        position: relative;
        display: block;
        overflow: hidden;

        img {
            box-sizing: border-box;
            position: relative;
            display: block;
            width: 100%;
            transform: scale(1);
            transition: transform 0.5s;
        }

        .overlay {
            box-sizing: border-box;
            position: absolute;
            display: block;
            left: 0px;
            top: 0px;
            width: 100%;
            height: 100%;
            background: rgba(255, 255, 255, 0.3);
            opacity: 0;
            transition: opacity 0.3s;
        }
    }

    .brand {
        box-sizing: border-box;
        position: relative;
        display: block;
        margin: 10px 0px 0px 0px;
        font-family: $poppins-semibold;
        font-size: 21px;
        line-height: 31px;
        letter-spacing: -0.05rem;
        color: #FC6C5A;
    }

    .title {
        box-sizing: border-box;
        position: relative;
        display: block;
        margin: 3px 0px 0px 0px;
        font-size: 19px;
        line-height: 28px;
        color: #000000;
    }
}

.project-link:hover {
    .img-holder {
        img {
            transform: scale(1.1);
        }

        .overlay {
            opacity: 1;
        }
    }
}

.project-link.gallery-format:hover {
    .img-holder {
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
    }
}