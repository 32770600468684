@import "../../styles/fonts";
@import "../../styles/animations";

.works {
    box-sizing: border-box;
    position: relative;
    display: block;

    .projects-block {
        box-sizing: border-box;
        position: relative;
        display: block;
        padding: 75px 0px 75px 0px;
        background: #f5f5f5;

        .main-heading {
            .title-holder {
                .extra {
                    max-width: 706px;
                }

                @media only screen and (max-width: 1280px) {
                    .extra {
                        max-width: none;
                    }
                }
            }
        }

        .projects-gallery {
            margin: 75px 0px 0px 0px;
        }
    }
}
