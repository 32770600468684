@import "../../styles/fonts";
@import "../../styles/animations";

.blog {
    box-sizing: border-box;
    position: relative;
    display: block;

    .intro-block {
        box-sizing: border-box;
        position: relative;
        display: block;
        padding: 75px 0px 0px 0px;
        background: #ffffff;

        .controls {
            box-sizing: border-box;
            position: relative;
            display: block;
            margin: 75px 0px 0px 0px;

            .search-box {
                box-sizing: border-box;
                position: relative;
                display: inline-block;
                vertical-align: bottom;
                padding: 0px 20px 0px 0px;
                width: 50%;

                .search-bar {
                    box-sizing: border-box;
                    position: relative;
                    display: block;
                    padding: 0px 90px 0px 0px;

                    .search-input {
                        box-sizing: border-box;
                        position: relative;
                        display: block;
                        padding: 30px 0px 30px 45px;
                        border: none;
                        border-radius: 45px 0px 0px 45px;
                        outline: none;
                        width: 100%;
                        background: #e8e8e8;
                        font-size: 19px;
                        line-height: 30px;
                    }

                    .search-btn {
                        box-sizing: border-box;
                        position: absolute;
                        display: block;
                        border: none;
                        outline: none;
                        border-radius: 0px 45px 45px 0px;
                        right: 0px;
                        top: 0px;
                        width: 90px;
                        height: 90px;
                        background-color: #e8e8e8;
                        background-image: url("data:image/svg+xml,%3Csvg width='35' height='35' viewBox='0 0 35 35' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='13.435' cy='13.435' r='8' transform='rotate(-45 13.435 13.435)' stroke='black' stroke-width='3'/%3E%3Crect x='18.3848' y='20.5061' width='3' height='12' transform='rotate(-45 18.3848 20.5061)' fill='black'/%3E%3C/svg%3E%0A");
                        background-repeat: no-repeat;
                        background-size: 35px;
                        background-position: left 10px center;
                        cursor: pointer;
                    }
                }
            }

            .filters {
                box-sizing: border-box;
                position: relative;
                display: inline-block;
                vertical-align: bottom;
                padding: 0px 0px 0px 20px;
                width: 50%;
                text-align: right;

                .filter-info {
                    box-sizing: border-box;
                    position: relative;
                    display: inline-block;
                    vertical-align: middle;

                    .text {
                        box-sizing: border-box;
                        position: relative;
                        display: block;
                        font-size: 19px;
                        line-height: 30px;
                    }
                }

                .filter1, .filter2 {
                    box-sizing: border-box;
                    position: relative;
                    display: inline-block;
                    vertical-align: middle;
                    padding: 0px 0px 0px 20px;
                    width: 100%;
                    max-width: 226px;

                    .filter-btn {
                        box-sizing: border-box;
                        position: relative;
                        display: block;
                        padding: 30px 20px;
                        border-radius: 45px;
                        background-color: #e8e8e8;
                        font-family: $poppins-semibold;
                        font-size: 19px;
                        line-height: 30px;
                        text-align: center;
                        cursor: pointer;
                    }

                    .filter-btn.enabled {
                        background-color: #FC6C5A; /* For browsers that do not support gradients */
                        background-image: linear-gradient(to top right, #FF841A, #FC6C5A);
                        color: #ffffff;
                    }
                }
            }
        }

        @media only screen and (max-width: 1320px) {
            .controls {
                .search-box {
                    padding: 0px;
                    width: 100%;
                }

                .filters {
                    margin: 20px 0px 0px 0px;
                    padding: 0px;
                    width: 100%;
                }
            }
        }

        @media only screen and (max-width: 590px) {
            .controls {
                .filters {
                    .filter-info {
                        display: none;
                    }

                    .filter1, .filter2 {
                        width: 50%;
                        max-width: none;
                    }

                    .filter1 {
                        padding: 0px 10px 0px 0px;
                    }

                    .filter2 {
                        padding: 0px 0px 0px 10px;
                    }
                }
            }
        }
    }

    .posts-block {
        box-sizing: border-box;
        position: relative;
        display: block;
        padding: 75px 0px;
        overflow: hidden;
        background: #ffffff;

        .loader-holder {
            box-sizing: border-box;
            position: relative;
            display: block;
            padding: 80px 0px 120px 0px;
        }

        .preview {
            box-sizing: border-box;
            position: relative;
            display: block;

            .posts {
                box-sizing: border-box;
                position: relative;
                display: block;
                margin-right: -10px;

                .post {
                    box-sizing: border-box;
                    position: relative;
                    display: inline-block;
                    vertical-align: top;
                    padding: 0px 10px 30px 0px;
                    width: 25%;
                }

                @media only screen and (max-width: 1100px) {
                    .post {
                        width: 33.33%;
                    }
                }

                @media only screen and (max-width: 500px) {
                    .post {
                        width: 50%;
                    }
                }
            }

            .buttons-holder {
                box-sizing: border-box;
                position: relative;
                display: block;
                margin: 20px 0px 0px 0px;
                text-align: center;

                .more-btn {
                    box-sizing: border-box;
                    position: relative;
                    display: inline-block;
                    vertical-align: top;
                    cursor: pointer;

                    .text {
                        box-sizing: border-box;
                        position: relative;
                        display: block;
                        margin: 0px 0px 10px 0px;
                        font-family: $poppins-semibold;
                        font-size: 21px;
                        line-height: 31px;
                    }

                    .icon {
                        box-sizing: border-box;
                        position: relative;
                        display: block;
                        margin: 0px auto;
                        padding: 3px;
                        border-radius: 50%;
                        width: 60px;
                        height: 60px;
                        background-color: #FC6C5A; /* For browsers that do not support gradients */
                        background-image: linear-gradient(to top right, #FF841A, #FC6C5A);

                        .arrow {
                            box-sizing: border-box;
                            position: relative;
                            display: block;
                            border-radius: 50%;
                            width: 100%;
                            height: 100%;
                            background-color: transparent;
                            background-image: url("data:image/svg+xml,%3Csvg width='17' height='23' viewBox='0 0 17 23' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.03629 -2.17764e-06L7.03629 17.4728L-4.66671e-07 10.8622L-1.37446e-06 15.0158L8.49781 23L17 15.0158L17 10.8663L9.9637 17.4728L9.96371 -1.53783e-06L7.03629 -2.17764e-06Z' fill='white'/%3E%3C/svg%3E%0A");
                            background-repeat: no-repeat;
                            background-position: center;
                            transition: background-color 0.3s;
                        }
                    }
                }

                .more-btn:hover {
                    .icon {
                        .arrow {
                            background-color: #ffffff;
                            background-image: url("data:image/svg+xml,%3Csvg width='17' height='23' viewBox='0 0 17 23' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.03629 -2.17764e-06L7.03629 17.4728L-4.66671e-07 10.8622L-1.37446e-06 15.0158L8.49781 23L17 15.0158L17 10.8663L9.9637 17.4728L9.96371 -1.53783e-06L7.03629 -2.17764e-06Z' fill='%23FC6C5A'/%3E%3C/svg%3E%0A");
                        }
                    }
                }
            }
        }

        .not-found {
            box-sizing: border-box;
            position: relative;
            display: block;
            margin: 0px;
            font-size: 19px;
            line-height: 30px;
        }
    }
}
