@import "../../styles/fonts";
@import "../../styles/animations";

.services {
    box-sizing: border-box;
    position: relative;
    display: block;

    .splash-block {
        box-sizing: border-box;
        position: relative;
        display: block;
        padding: 75px 0px;
        background-color: #FC6C5A; /* For browsers that do not support gradients */
        background-image: linear-gradient(to top right, #FF841A, #FC6C5A);
        overflow: hidden;

        .bg-img1 {
            box-sizing: border-box;
            position: absolute;
            display: block;
            right: -945px;
            top: -870px;
            width: 1532px;
            height: 1532px;
            background-image: url("data:image/svg+xml,%3Csvg width='1532' height='1532' viewBox='0 0 1532 1532' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg style='mix-blend-mode:screen'%3E%3Cpath d='M1432 766C1432 1133.82 1133.82 1432 766 1432C398.178 1432 100 1133.82 100 766C100 398.178 398.178 100 766 100C1133.82 100 1432 398.178 1432 766Z' stroke='url(%23paint0_linear_249_600)' stroke-width='200'/%3E%3C/g%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_249_600' x1='-167' y1='1103.5' x2='59.3777' y2='1553.55' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23FEA46A' stop-opacity='1'/%3E%3Cstop offset='1' stop-color='%23FD8572' stop-opacity='1'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");
            background-repeat: no-repeat;
            background-size: 100%;
        }

        .bg-img2 {
            box-sizing: border-box;
            position: absolute;
            display: block;
            left: -2730px;
            bottom: -2400px;
            width: 3682px;
            height: 3682px;
            background-image: url("data:image/svg+xml,%3Csvg width='3682' height='3682' viewBox='0 0 3682 3682' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg style='mix-blend-mode:screen'%3E%3Cpath d='M3482 1841C3482 2747.3 2747.3 3482 1841 3482C934.701 3482 200 2747.3 200 1841C200 934.701 934.701 200 1841 200C2747.3 200 3482 934.701 3482 1841Z' stroke='url(%23paint0_linear_249_598)' stroke-opacity='1' stroke-width='400'/%3E%3C/g%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_249_598' x1='-255.962' y1='3218.14' x2='1264.64' y2='4057.53' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23FE9247'/%3E%3Cstop offset='1' stop-color='%23FD8C5D'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");
            background-repeat: no-repeat;
            background-size: 100%;
            transform: rotate(45deg);
        }

        .splash-screen {
            .heading-holder {
                width: 55%;

                .svg {
                    max-width: 640px;
                }
            }

            .img1-holder {
                width: 45%;

                img {
                    margin: 0px;
                    max-width: 574px;
                }
            }

            .img2-holder {
                width: 58%;

                img {
                    max-width: 600px;
                }
            }

            .body-holder {
                width: 42%;
            }
        }
    }

    @media only screen and (max-width: 1000px) {
        .splash-block {
            .bg-img1 {
                display: none;
            }

            .bg-img2 {
                display: none;
            }

            .splash-screen {
                .heading-holder {
                    width: 100%;
                }

                .img1-holder {
                    width: 100%;

                    img {
                        margin: 0px auto;
                    }
                }

                .img2-holder {
                    width: 100%;
                }

                .body-holder {
                    width: 100%;
                }
            }
        }
    }

    .services-block {
        box-sizing: border-box;
        position: relative;
        display: block;
        padding: 75px 0px;
        background-color: #000000;

        .carousel {
            .service {
                box-sizing: border-box;
                position: relative;
                display: block;
                border-right: 4px solid #ffffff;
                padding: 0px 90px;
                font-family: $poppins-semibold;
                font-size: 150px;
                line-height: 190px;
                color: #FC6C5A;
            }

            @media only screen and (max-width: 1100px) {
                .service {
                    font-size: 40px;
                    line-height: 52px;
                }
            }
        }
    }

    @media only screen and (max-width: 1100px) {
        .services-block {
            padding: 50px 0px;
        }
    }

    .skills-block {
        box-sizing: border-box;
        position: relative;
        display: block;
        padding: 100px 0px 0px 0px;

        .svg-holder {
            box-sizing: border-box;
            position: relative;
            display: block;
            width: 100%;

            img {
                box-sizing: border-box;
                position: relative;
                display: block;
                width: 100%;
                max-width: 1262px;
            }
        }

        .txt-holder {
            box-sizing: border-box;
            position: relative;
            display: block;
            margin: 60px 0px 0px 0px;

            .title {
                box-sizing: border-box;
                position: relative;
                display: inline-block;
                vertical-align: top;
                padding: 0px 40px 0px 0px;
                width: 35%;
                text-align: right;

                h3 {
                    box-sizing: border-box;
                    position: relative;
                    display: inline-block;
                    vertical-align: top;
                    margin: 0px;
                    width: 100%;
                    max-width: 300px;
                    font-family: $poppins-semibold;
                    font-weight: normal;
                    text-decoration: underline;
                    font-size: 45px;
                    line-height: 54px;
                }
            }

            .paragraphs {
                box-sizing: border-box;
                position: relative;
                display: inline-block;
                vertical-align: top;
                padding: 10px 0px 0px 0px;
                width: 65%;
                columns: 2;
                column-gap: 40px;

                p {
                    box-sizing: border-box;
                    position: relative;
                    display: block;
                    margin: 0px;
                    font-family: $poppins-light;
                    font-size: 29px;
                    line-height: 39px;
                    letter-spacing: -1px;
                    break-inside: avoid;
                }
            }            
        }

        @media only screen and (max-width: 1180px) {
            .txt-holder {
                .title {
                    width: 100%;
                    padding: 0px 0px 0px 0px;
                    text-align: left;

                    h3 {
                        max-width: none;
                        font-size: 30px;
                        line-height: 38px;
                    }
                }

                .paragraphs {
                    padding: 40px 0px 0px 0px;
                    width: 100%;
                    column-gap: 20px;

                    p {
                        font-size: 19px;
                        line-height: 30px;
                    }
                }
            }
        }

        @media only screen and (max-width: 680px) {
            .txt-holder {
                .paragraphs {
                    padding: 20px 0px 0px 0px;
                    columns: 1;

                    p {
                        margin: 20px 0px 0px 0px;
                        font-size: 19px;
                        line-height: 30px;
                    }
                }
            }
        }

        .carousel {
            margin: 100px 0px 0px 0px;

            .tiles {
                box-sizing: border-box;
                position: relative;
                display: block;
                padding: 0px 0px 0px 8px;

                .col1 {
                    box-sizing: border-box;
                    position: relative;
                    display: inline-block;
                    vertical-align: top;
                    width: 600px;
                }

                .col2 {
                    box-sizing: border-box;
                    position: relative;
                    display: inline-block;
                    vertical-align: top;
                    padding: 0px 0px 0px 8px;
                    width: 635px;
                }

                .subcol1 {
                    box-sizing: border-box;
                    position: relative;
                    display: inline-block;
                    vertical-align: top;
                    width: 296px;
                }

                .subcol2 {
                    box-sizing: border-box;
                    position: relative;
                    display: inline-block;
                    vertical-align: top;
                    width: 304px;
                    padding: 0px 0px 0px 8px;
                }

                .row {
                    box-sizing: border-box;
                    position: relative;
                    display: block;
                }

                .row2 {
                    padding: 8px 0px 0px 0px;
                }

                img {
                    box-sizing: border-box;
                    position: relative;
                    display: block;
                    width: 100%;
                }
            }
        }
    }

    .philosophy-block {
        box-sizing: border-box;
        position: relative;
        display: block;
        padding: 100px 0px;

        .svg-holder {
            box-sizing: border-box;
            position: relative;
            display: block;
            padding: 0px 80px;
            width: 100%;
            text-align: right;

            img {
                box-sizing: border-box;
                position: relative;
                display: inline-block;
                vertical-align: top;
                width: 100%;
                max-width: 1094px;
            }
        }

        .txt-holder {
            box-sizing: border-box;
            position: relative;
            display: block;
            margin: -40px 0px 0px 0px;
            padding: 0px 0px 0px 100px;

            .title {
                box-sizing: border-box;
                position: relative;
                display: block;
                width: 100%;

                h3 {
                    box-sizing: border-box;
                    position: relative;
                    display: block;
                    margin: 0px;
                    width: 100%;
                    max-width: 300px;
                    font-family: $poppins-semibold;
                    font-weight: normal;
                    text-decoration: underline;
                    font-size: 45px;
                    line-height: 54px;
                }
            }

            .paragraphs {
                box-sizing: border-box;
                position: relative;
                display: block;
                padding: 40px 0px 0px 0px;
                width: 100%;
                max-width: 960px;
                columns: 2;
                column-gap: 40px;

                p {
                    box-sizing: border-box;
                    position: relative;
                    display: block;
                    margin: 0px;
                    font-family: $poppins-light;
                    font-size: 29px;
                    line-height: 39px;
                    letter-spacing: -1px;
                    break-inside: avoid;
                }
            }            
        }

        @media only screen and (max-width: 1492px) {
            .svg-holder {
                padding: 0px;
                text-align: center;
            }

            .txt-holder {
                margin: 60px 0px 0px 0px;
                padding: 0px;

                .title {
                    h3 {
                        max-width: none;
                    }
                }

                .paragraphs {
                    max-width: none;
                }
            }
        }

        @media only screen and (max-width: 1020px) {
            .txt-holder {
                .title {
                    h3 {
                        font-size: 30px;
                        line-height: 38px;
                    }
                }

                .paragraphs {
                    column-gap: 20px;

                    p {
                        font-size: 19px;
                        line-height: 30px;
                    }
                }
            }
        }

        @media only screen and (max-width: 680px) {
            .txt-holder {
                .paragraphs {
                    padding: 20px 0px 0px 0px;
                    columns: 1;

                    p {
                        margin: 20px 0px 0px 0px;
                        font-size: 19px;
                        line-height: 30px;
                    }
                }
            }
        }
    }
}
