@import "../../styles/fonts";
@import "../../styles/animations";

.about {
    box-sizing: border-box;
    position: relative;
    display: block;

    .splash-block {
        box-sizing: border-box;
        position: relative;
        display: block;
        padding: 75px 0px;
        background-color: #FC6C5A; /* For browsers that do not support gradients */
        background-image: linear-gradient(to top right, #FF841A, #FC6C5A);
        overflow: hidden;

        .bg-img1 {
            box-sizing: border-box;
            position: absolute;
            display: block;
            right: -945px;
            top: -1000px;
            width: 1532px;
            height: 1532px;
            background-image: url("data:image/svg+xml,%3Csvg width='1532' height='1532' viewBox='0 0 1532 1532' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg style='mix-blend-mode:screen'%3E%3Cpath d='M1432 766C1432 1133.82 1133.82 1432 766 1432C398.178 1432 100 1133.82 100 766C100 398.178 398.178 100 766 100C1133.82 100 1432 398.178 1432 766Z' stroke='url(%23paint0_linear_249_600)' stroke-width='200'/%3E%3C/g%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_249_600' x1='-167' y1='1103.5' x2='59.3777' y2='1553.55' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23FEA46A' stop-opacity='1'/%3E%3Cstop offset='1' stop-color='%23FD8572' stop-opacity='1'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");
            background-repeat: no-repeat;
            background-size: 100%;
        }

        .bg-img2 {
            box-sizing: border-box;
            position: absolute;
            display: block;
            left: -2730px;
            bottom: -2400px;
            width: 3682px;
            height: 3682px;
            background-image: url("data:image/svg+xml,%3Csvg width='3682' height='3682' viewBox='0 0 3682 3682' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg style='mix-blend-mode:screen'%3E%3Cpath d='M3482 1841C3482 2747.3 2747.3 3482 1841 3482C934.701 3482 200 2747.3 200 1841C200 934.701 934.701 200 1841 200C2747.3 200 3482 934.701 3482 1841Z' stroke='url(%23paint0_linear_249_598)' stroke-opacity='1' stroke-width='400'/%3E%3C/g%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_249_598' x1='-255.962' y1='3218.14' x2='1264.64' y2='4057.53' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23FE9247'/%3E%3Cstop offset='1' stop-color='%23FD8C5D'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");
            background-repeat: no-repeat;
            background-size: 100%;
            transform: rotate(45deg);
        }
    }

    @media only screen and (max-width: 1000px) {
        .splash-block {
            .bg-img1 {
                display: none;
            }

            .bg-img2 {
                display: none;
            }
        }
    }

    .video-block {
        box-sizing: border-box;
        position: relative;
        display: block;
        height: 600px;
    }

    .culture-block {
        box-sizing: border-box;
        position: relative;
        display: block;
        padding: 75px 0px;
        background: #F5F5F5;

        .box {
            box-sizing: border-box;
            position: relative;
            display: block;
            padding: 10px 0px 40px 0px;
            text-align: left;

            .list {
                box-sizing: border-box;
                position: relative;
                margin: 0px;
                padding: 0px;
                text-align: left;

                .item {
                    box-sizing: border-box;
                    position: relative;
                    margin: 20px 0px 0px 0px;
                    padding: 0px 0px 0px 43px;

                    .arrow {
                        box-sizing: border-box;
                        position: absolute;
                        display: block;
                        left: 0px;
                        top: 3px;
                        width: 33px;
                        height: 23px;
                        background-image: url("data:image/svg+xml,%3Csvg width='33' height='23' viewBox='0 0 33 23' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 13.4803L25.0697 13.4803L15.585 23H21.5444L33 11.503L21.5444 0L15.5908 0L25.0697 9.51969L0 9.51969L0 13.4803Z' fill='url(%23paint0_linear_16_587)'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_16_587' x1='2.38046' y1='21.6187' x2='20.802' y2='3.82989' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23FF841A'/%3E%3Cstop offset='1' stop-color='%23FC6C5A'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");
                        background-repeat: no-repeat;
                        background-size: 100%;
                        background-position: center;
                    }

                    .text {
                        box-sizing: border-box;
                        position: relative;
                        display: block;
                        font-family: $poppins-semibold;
                        font-size: 30px;
                        line-height: 30px;
                    }
                }

                .item:first-child {
                    margin: 0px;
                }
            }

            .paragraphs {
                box-sizing: border-box;
                position: relative;
                display: inline-block;
                vertical-align: top;
                width: 100%;

                p {
                    box-sizing: border-box;
                    position: relative;
                    display: inline-block;
                    vertical-align: top;
                    margin: 44px 0px 44px 0px;
                    font-family: $poppins-light;
                    font-size: 29px;
                    line-height: 39px;
                    letter-spacing: -1px;
                }

                p:first-child {
                    margin: 0px;
                }
            }

            .extra {
                box-sizing: border-box;
                position: absolute;
                display: block;
                right: 0px;
                top: 0px;
                width: 100%;
                max-width: 960px;
                text-align: left;


                p {
                    box-sizing: border-box;
                    position: relative;
                    display: inline-block;
                    vertical-align: top;
                    margin: 44px 0px 0px 0px;
                    font-family: $poppins-light;
                    font-size: 29px;
                    line-height: 39px;
                    letter-spacing: -1px;
                }

                p:first-child {
                    margin: 0px;
                }
            }

            @media only screen and (max-width: 1660px) {
                .list {
                    .item {
                        .text {
                            font-size: 19px;
                            line-height: 30px;
                        }
                    }
                }

                .paragraphs {
                    max-width: none;

                    p {
                        font-size: 19px;
                        line-height: 30px;
                    }
                }

                .extra {
                    position: relative;
                    margin: 40px 0px 0px 0px;
                    right: auto;
                    top: auto;
                    max-width: none;

                    p {
                        font-size: 19px;
                        line-height: 30px;
                    }
                }
            }
        }
    }

    .awards-block {
        box-sizing: border-box;
        position: relative;
        display: block;
        padding: 75px 0px;
        background-color: #FC6C5A;
        overflow: hidden;

        .section-heading {
            border-top: 1px solid #ffffff;
            color: #ffffff;
        }

        .awards {
            box-sizing: border-box;
            position: relative;
            display: block;
            margin: 30px 0px 0px 0px;

            .award {
                box-sizing: border-box;
                position: relative;
                display: block;
                padding: 0px 30px;

                img {
                    box-sizing: border-box;
                    position: relative;
                    display: block;
                }
            }
        }
    }

    .team-block {
        box-sizing: border-box;
        position: relative;
        display: block;
        padding: 75px 0px 45px 0px;
        background: #000000;
        overflow: hidden;

        .section-heading {
            border-top: 1px solid #ffffff;
            color: #ffffff;
        }

        .members {
            box-sizing: border-box;
            position: relative;
            display: block;
            margin: 40px -10px 0px 0px;

            .member {
                box-sizing: border-box;
                position: relative;
                display: inline-block;
                vertical-align: top;
                padding: 0px 10px 30px 0px;
                width: 25%;

                img {
                    box-sizing: border-box;
                    position: relative;
                    display: block;
                    width: 100%;
                }

                .name {
                    box-sizing: border-box;
                    position: relative;
                    display: block;
                    margin: 10px 0px 0px 0px;
                    font-family: $poppins-semibold;
                    font-size: 21px;
                    line-height: 31px;
                    color: #FC6C5A;
                }

                .title {
                    box-sizing: border-box;
                    position: relative;
                    display: block;
                    margin: 3px 0px 0px 0px;
                    font-size: 19px;
                    line-height: 28px;
                    color: #ffffff;
                }
            }
        }

        @media only screen and (max-width: 1100px) {
            .members {
                .member {
                    width: 33.33%;

                    .name {
                        font-size: 16px;
                        line-height: 24px;
                    }

                    .title {
                        font-size: 12px;
                        line-height: 20px;
                    }
                }
            }
        }

        @media only screen and (max-width: 500px) {
            .members {
                .member {
                    width: 50%;
                }
            }
        }
    }
}
