@import "../../styles/fonts";
@import "../../styles/animations";

.topbar {
    box-sizing: border-box;
    position: fixed;
    display: block;
    left: auto;
    top: 0px;
    width: 100%;
    height: 90px;
    background-color: #ffffff;
    z-index: 2;
    transition: top 0.3s;

    .inner {
        box-sizing: border-box;
        position: relative;
        display: block;
        margin: 0px auto;
        padding: 0px 100px;
        width: 100%;
        max-width: 1740px;

        .logo {
            box-sizing: border-box;
            position: relative;
            display: block;
            top: 21px;
            width: 100%;
            max-width: 220px;
            height: 48px;
            background-image: url('../../media/imgs/ignition_logo_normal.svg');
            background-repeat: no-repeat;
            background-size: 100%;
            text-decoration: none;
        }

        .tabs {
            box-sizing: border-box;
            position: absolute;
            display: block;
            right: 80px;
            top: 31px;

            .tab {
                box-sizing: border-box;
                position: relative;
                display: inline-block;
                vertical-align: bottom;
                padding: 0px 20px;
                font-size: 18px;
                line-height: 27px;
                text-decoration: none;
                text-align: center;
                text-decoration: none;
                color: #505050;
            }

            .tab.active, .tab:hover {
                text-decoration: underline;
                text-decoration-color: #FC6C5A;
                text-underline-offset: 6px;
                text-decoration-thickness: 3px;
            }
        }

        .menu-btn {
            box-sizing: border-box;
            position: absolute;
            display: none;
            right: 8px;
            top: 18px;
            width: 54px;
            height: 54px;
            cursor: pointer;

            .line {
                box-sizing: border-box;
                position: absolute;
                display: block;
                left: 12px;
                width: 30px;
                height: 6px;
                background: #000000;
                transition: top 0.3s, transform 0.3s;
            }

            .line1 {
                top: 17px;
            }

            .line2 {
                top: 31px;
            }
        }

        .menu-btn:hover {
            .line {
                background: #FC6C5A;
            }
        }

        .menu-btn.active {
            .line1 {
                top: 24px;
                transform: rotate(45deg);
            }

            .line2 {
                top: 24px;
                transform: rotate(-45deg);
            }
        }

        @media only screen and (max-width: 1020px) {
            .tabs {
                display: none;
            }

            .menu-btn {
                display: block;
            }
        }
    }

    @media only screen and (max-width: 1020px) {
        .inner {
            padding: 0px 20px;
        }
    }

    .overlay {
        box-sizing: border-box;
        position: absolute;
        display: block;
        left: 0px;
        top: 0px;
        width: 100%;
        height: 100vh;
        overflow: hidden;
        pointer-events: none;

        .panel {
            box-sizing: border-box;
            position: absolute;
            display: block;
            padding: 90px 0px 0px 0px;
            right: -100%;
            top: 0px;
            width: 100%;
            height: 100%;
            pointer-events: none;
            transition: right 0.5s;

            .tabs {
                box-sizing: border-box;
                position: relative;
                display: block;
                padding: 40px 20px 0px 20px;
                height: 100%;
                background-color: #FFFFFF;
                pointer-events: auto;

                .tab {
                    box-sizing: border-box;
                    position: relative;
                    display: block;
                    margin: 10px 0px 0px 0px;
                    padding: 0px 0px 0px 50px;
                    text-decoration: none;
                    cursor: pointer;

                    .arrow {
                        box-sizing: border-box;
                        position: absolute;
                        display: block;
                        left: 0px;
                        top: 12px;
                        width: 33px;
                        height: 24px;
                        background-image: url("data:image/svg+xml,%3Csvg width='67' height='49' viewBox='0 0 67 49' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 28.7189L50.8991 28.7189L31.6422 49H43.7417L67 24.5063L43.7417 0L31.6541 0L50.8991 20.2811L0 20.2811L0 28.7189Z' fill='url(%23paint0_linear_387_1934)'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_387_1934' x1='67' y1='-15' x2='-4.5' y2='49' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23FC6C5A'/%3E%3Cstop offset='1' stop-color='%23FF841A'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");
                        background-repeat: no-repeat;
                        background-size: 100%;
                    }

                    .text {
                        box-sizing: border-box;
                        position: relative;
                        display: block;
                        font-family: $poppins-semibold;
                        font-size: 35px;
                        line-height: 48px;
                        color: #000000;
                    }
                }

                .tab:first-child {
                    margin: 0px;
                }

                .tab:hover {
                    .text {
                        color: #FC6C5A;
                    }
                }
            }
        }
    }

    .overlay.open {
        .panel {
            right: 0px;
        }
    }
}

.topbar.hidden {
    top: -90px;
}
